<template>
  <!-- div background form -->
  <div class="resend-page d-flex justify-center">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="resend-form"
    >
      <v-card class="resend-card">
        <v-card-title class="resend-card-title justify-center">
          <span>Resend Account</span>
        </v-card-title>
        <!-- Content Card Form -->
        <v-card-text class="px-0">
          <v-text-field
            v-model="dataForm.email"
            :rules="emailRules"
            label="Email"
            prepend-icon="mdi-email-outline"
          />
        </v-card-text>
        <!-- v-card-actions -->
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-btn
                x-large
                rounded
                :disabled="!valid"
                color="success"
                width="100%"
                @click="resend"
              >
                Resend
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              align="center"
            >
              <v-btn
                text
                @click="$router.push('verify-register')"
              >
                Verify
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <!-- end v-card-actions -->
      </v-card>
    </v-form>
  </div>
</template>

<script>
  import Repository from '../repository/RepositoryFactory'
  const RegisterRepository = Repository.get('register')
  export default {
    name: 'ResendVerifyRegisterForm',
    data: () => ({
      dataForm: {
        email: '',
      },
      valid: true,
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
    }),
    methods: {
      async resend () {
        const valueCheck = await this.$refs.form.validate()
        // if valueCheck = false then stop function resend
        if (!valueCheck) {
          return
        }
        // Data validate ok
        const email = this.dataForm.email
        // call API resend
        this.showProgressCircular()
        const resendRegisterRepository = await RegisterRepository.resendCodeEmail(email)
        this.hideProgressCircular()
        // resendRegister = API return data
        const resendRegister = resendRegisterRepository.data
        // if resendRegister.status = false then error resendRegister.data.message
        if (!resendRegister.status) {
          this.$toast.error(resendRegister.data.message)
          return
        }
        // if resendRegister.status = true then success resendRegister.data.checkEmail.message
        // then push page VerifyRegisterForm
        this.$toast.success(resendRegister.data.checkEmail.message)
        this.$router.push('verify-register')
      },
    },
    // Title page
    metaInfo: {
      titleTemplate: 'Resend Verify Register',
    },
  }
</script>

<style lang="scss" scoped>
  // full background desktop 100vh
  $fullScreen: 100vh;
  $backgroundImage: url("https://colorlib.com/etc/lf/Login_v4/images/bg-01.jpg");
  .resend-page {
    background-image: $backgroundImage;
    background-size: cover;
    padding: 15px;
    min-height: $fullScreen;
    .resend-form {
      width: 500px;
      .resend-card {
        padding: 55px;
        &-title {
          font-size: 39px;
          font-weight: bold;
        }
      }
    }
  }
  // media mobie
  @media only screen and (max-width: 600px) {
    .resend-card {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
</style>
